/*!

=========================================================
* fxpricezone
=========================================================

* Product Page: fxpricezone.com
* Copyright 2021 fxpricezone

* Coded by Muhammad Amir

=========================================================
*/

// variables and mixins
@import "fxpricezone/variables";
@import "fxpricezone/mixins";
@import "fxpricezone/shadows";

// plugin css
@import "fxpricezone/plugins/plugin-nouislider";
@import "fxpricezone/plugins/plugin-perfect-scrollbar";
@import "fxpricezone/plugins/plugin-react-datetime";
@import "fxpricezone/plugins/plugin-react-bootstrap-sweetalert";
@import "fxpricezone/plugins/plugin-react-chartist";
@import "fxpricezone/plugins/plugin-react-big-calendar";
@import "fxpricezone/plugins/plugin-react-jvectormap";
@import "fxpricezone/plugins/plugin-react-table";
@import "fxpricezone/plugins/plugin-react-tagsinput";

// Core CSS
@import "fxpricezone/misc";
@import "fxpricezone/fileupload";
@import "fxpricezone/fixed-plugin";
